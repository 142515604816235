import { domain } from '@utils/constants';

export const acceptGDPRCookies = (cookies = []) => {
  cookies.forEach(cookie => {
    setCookie(cookie, true, 90);
  });
};

export const declineGDPRCookies = (cookies = []) => {
  cookies.forEach(cookie => {
    setCookie(cookie, false, 90);
  });
};

export const setCookiesConsent = state => {
  localStorage.setItem('cookiesConsent', state);
};

export const setCookie = (name, value, exirationDays) => {
  const d = new Date();
  d.setTime(d.getTime() + exirationDays * 24 * 60 * 60 * 1000);
  const cExpires = `expires=${d.toUTCString()}`;
  const cDomain = domain ? `domain=${domain}` : '';
  const cValue = `${name}=${value}`;

  document.cookie = `${cValue};${cExpires};${cDomain}`;
};

export const removeCookie = name => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export const getCookie = name => {
  const cname = name + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(cname) == 0) {
      return c.substring(cname.length, c.length);
    }
  }

  return '';
};
