/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import App from './src/App';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider } from 'react-redux';
import store from './src/state/store';
import LangContextProvider from './src/context/LangContextProvider';
import { version } from './src/utils/constants';
import { getUser } from './src/services/auth';
import { PositionProvider } from './src/services/positionWatcher';
import {
  BUGSNAG_APIKEY,
  BUILD_ENV,
  NODE_ENV,
} from '@gatsby-local-plugin/gatsby-local-env-variables';

Bugsnag.start({
  apiKey: BUGSNAG_APIKEY,
  plugins: [new BugsnagPluginReact()],
  //
  // Checking NODE_ENV variable
  // to handle case which our env in project is production but
  // we are using develop
  //
  // So in this case any error won't be sent to bugsnag
  //
  // state - QA
  // development - Local development
  releaseStage: NODE_ENV == 'development' ? NODE_ENV : BUILD_ENV,
  enabledReleaseStages: ['production', 'stage'],
  appVersion: version,
  onError: function(event) {
    const user = getUser();
    if ('id' in user && user.id != null) {
      event.setUser(user.id, user.emailAddress, user.fullName);
    }
  },
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <PositionProvider>
        <ErrorBoundary>
          <LangContextProvider>
            <App>{element}</App>
          </LangContextProvider>
        </ErrorBoundary>
      </PositionProvider>
    </Provider>
  );
};
