import {
  API_HOST,
  FACEBOOK_APPID,
  ENABLE_MOCKUP,
  GOOGLE_MAPS_API_KEY,
  DOMAIN,
  SITE_DOMAIN,
  BACKOFFICE_DOMAIN,
  SALT_JWT,
} from '@gatsby-local-plugin/gatsby-local-env-variables';

export const apiHost = API_HOST;

export const shortLink = '/api/v1/shortener/create';

export const domain = DOMAIN || null;
export const siteDomain = SITE_DOMAIN;
export const backofficeDomain = BACKOFFICE_DOMAIN;

export const saltJwt = SALT_JWT;

export const version = '4.1.0';

export const enableMockup = ENABLE_MOCKUP;
export const apiKeyGoogleMaps = GOOGLE_MAPS_API_KEY;

export const contactInfo = '/api/v1/system/getcontacts';

export const loginRoute = '/api/v1/user/signin';
export const signupRoute = '/api/v1/user/signup';
export const forgotPasswordRoute = '/api/v1/user/forgotpassword';
export const facebookLoginRoute = '/api/v1/user/connectwithfacebook';
export const putUserRoute = '/api/v1/user/update';
export const deleteUser = '/api/v1/user/delete';
export const resetPasswordRoute = '/api/v1/user/changepassword';
export const resetPasswordOperatorRoute = '/api/v1/operator/changepassword';
export const addContactPhoneRoute = '/api/v1/user/update/contactphone';
export const sendVerificationCodeRoute = '/api/v1/user/sendvalidationcode';
export const validateVerificationCodeRoute =
  '/api/v1/user/validatemobilenumber';
export const registerGclidRoute = '/api/v1/user/registergclid';
export const sendMessageWhatsappRoute = '/api/v1/twilio/sendmessage';

// payments
export const paymentMethodsRoute = '/api/v1/payment/getpaymentmethods';
export const getInstallments = '/api/v1/payment/getInstallments';
export const execPaymentRoute = '/api/v1/payment/purchase';

export const getUser = '/api/v1/user/getuser';
export const addPaymentMethodsRoute = '/api/v1/payment/addpaymentmethod';

// Company
export const company = '/api/v1/company/checkauthorization';

// EVE
export const programEVEBH = '/api/v1/aviationprogram/eve-bh/create';
export const programEVEinfo = '/api/v1/aviationprogram/eve-bh/info';

// FLYSAFE
export const checkAuthorization = '/api/v1/aircraft/checkauthorization';

// passengers
export const passengersListRoute = '/api/v1/passenger/get';

export const newsletterRoute = '/api/v1/newsletter/subscribe';
export const sendMessageRoute = '/api/v1/user/sendmessage';
export const creditCardAllowedCountriesRoute =
  '/api/v1/payment/creditcard/getallowedcountries';

export const getLocationsRoute = '/api/v1/location/getlocations';

export const facebookAppId = FACEBOOK_APPID;

export const getFlightByIdRoute = '/api/v1/flight/getflightbyid';
export const getAircraftByIdRoute = '/api/v1/aircraft/getaircraftbyid';
export const getAircraftsByModel = '/api/v1/aircraft/get?id=';

export const getDocumentTypes = '/api/v1/user/document/gettypes';

export const platformFlightRoute = '/p/flight';

export const searchRoute = '/api/v1/flight/quote';

export const createProposal = '/api/v1/quotation/proposal/create';

// address
export const getAddressesRoute = '/api/v1/user/getaddresses';
export const addAddressRoute = '/api/v1/user/addaddress';

// proposal
export const acceptProposal = '/api/v1/quotation/proposal/accept';
export const sendProposal = '/api/v1/quotation/proposal/sendcontractemail';
export const createCart = '/api/v1/cart/create';

// language
export const langDict = {
  pt: { default: 'pt-BR', variant: 'pt_BR' },
  en: { default: 'en-US', variant: 'en_US' },
  es: { default: 'es-ES', variant: 'es_ES' },
  // it: { default: 'it-IT', variant: 'it_IT' },
};

// user
export const userPreferencesRoute = '/api/v1/client/getpreferences';
export const userPreferences = '/api/v1/user/getpreferences';
export const updatePreferences = '/api/v1/user/updatepreferences';

// shared
export const getAvailableFlightsStats =
  '/api/v1/flight/getavailableflights/stats';

// social
export const whatsapp = {
  url: 'https://wa.me/551132303710',
  params: 'text=Ol%C3%A1',
  icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="31" height="31" viewBox="0 0 41.187 41.372"><defs><style>.wpp-a{fill:url(#a);}.wpp-b{fill:#fff;fill-rule:evenodd;}</style><linearGradient id="a" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#20b038"/><stop offset="0.517" stop-color="#55cf61"/><stop offset="1" stop-color="#60d66a"/></linearGradient></defs><path class="wpp-a" d="M27.875,62.454l2.909-10.623A20.508,20.508,0,1,1,48.555,62.1h-.009a20.5,20.5,0,0,1-9.8-2.495Z" transform="translate(-27.875 -21.082)"/><path class="wpp-b" d="M312.774,323.957c-.4-.884-.816-.9-1.194-.917-.309-.013-.664-.013-1.017-.013a1.95,1.95,0,0,0-1.415.664,5.95,5.95,0,0,0-1.858,4.427,10.326,10.326,0,0,0,2.167,5.49c.265.354,3.672,5.884,9.067,8.012,4.484,1.768,5.4,1.416,6.369,1.328s3.14-1.284,3.583-2.523a4.434,4.434,0,0,0,.31-2.523c-.133-.221-.487-.354-1.017-.619s-3.14-1.55-3.627-1.727-.84-.265-1.194.266-1.371,1.726-1.68,2.08-.619.4-1.15.133a14.519,14.519,0,0,1-4.268-2.634,15.988,15.988,0,0,1-2.953-3.676c-.31-.531-.033-.818.233-1.083.238-.238.531-.62.8-.93a3.638,3.638,0,0,0,.53-.885.977.977,0,0,0-.044-.93C314.277,327.632,313.246,325.007,312.774,323.957Z" transform="translate(-297.401 -312.342)"/></svg>`,
  tracking: {
    event_category: 'message',
    event_action: 'whatsapp',
    event_label: 'User contacts via whatsapp',
  },
};
// sales
export const aircraftSalesRequest = '/api/v1/aircraft/requestsale/create';

// messages
export const MSG_TYPE_CHECK_AVAILABILITY = 'CHECK_AVAILABILITY';
